/* eslint-disable max-lines */
import {
	ContactSources,
	ContactSourceNames
} from 'utils/constants';
import { Flags } from './helpers';

export default {
	isUserFetched({ profile }) {
		return Boolean(profile !== null);
	},

	views({ views }) {
		return views;
	},

	isAutomationsWelcomeDismissed({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.WELCOMEMSG));
	},

	isCampaignsWelcomeDismissed({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.CAMPAIGNS_WELCOME_MSG));
	},

	isCampaignsSequenceWelcomeDismissed({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.CAMPAIGNS_SEQUENCE_WELCOME_MSG));
	},

	isCampaignsSingleEmailWelcomeDismissed({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.CAMPAIGNS_SINGLE_EMAIL_WELCOME_MSG));
	},

	isCampaignsDeliverabilityMessageDismissed({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.EMAIL_DELIVERABILITY_MSG));
	},

	isTrialEnding({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.TRIAL_ENDING));
	},

	isPaymentFailed({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.PAYMENT_FAILED));
	},

	// Integrations

	areAllIntegrationsConnected(state, getters) {
		return Boolean(getters.isCrmConnected && getters.isMailchimpConnected);
	},

	isOutfunnelDatabaseConnected({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.CONTACTS_IMPORTED));
	},

	isCrmConnected(state, getters) {
		return Boolean(
			getters.isPipedriveConnected
			|| getters.isCopperConnected
			|| getters.isOutfunnelDatabaseConnected
			|| getters.isHubspotCrmConnected
			|| getters.isAirtableConnected
		);
	},

	/**
	 * @param {Object} state
	 * @param {{
	 * isPipedriveConnected: Boolean
	 * isCopperConnected: Boolean
	 * }} getters
	 * @return {Boolean}
	 */
	isExternalCrmConnected(state, getters) {
		return Boolean(
			getters.isPipedriveConnected
			|| getters.isCopperConnected
			|| getters.isHubspotCrmConnected
			|| getters.isAirtableConnected
		);
	},

	isPipedriveConnected({ profile }) {
		return Boolean(profile?.integrations?.pipedrive);
	},

	/**
	 * @param {Object} params
	 * @param {{integrations: {hubspot?: Object}}} params.profile
	 * @return {Boolean}
	 */
	isHubspotCrmConnected({ profile }) {
		return Boolean(profile?.integrations?.hubspotCrm);
	},

	isCopperConnected({ profile }) {
		return Boolean(profile?.integrations?.copper);
	},

	isAirtableConnected({ profile }) {
		return Boolean(profile?.integrations?.airtable);
	},

	isFacebookConnected({ profile }) {
		return Boolean(profile?.integrations?.facebook);
	},

	isFacebookAdsConnected({ profile }) {
		return Boolean(profile?.integrations?.facebookAds);
	},

	isCalendlyConnected({ profile }) {
		return Boolean(profile?.integrations?.calendly);
	},

	hasCopperIntent({ profile }) {
		return Boolean(profile?.intents?.includes('copper'));
	},

	isLinkedInConnected({ profile }) {
		return Boolean(profile?.integrations?.linkedin);
	},

	isWixConnected({ profile }) {
		return Boolean(profile?.integrations?.wix);
	},

	isElementorConnected({ profile }) {
		return Boolean(profile?.integrations?.elementor?.sites?.length);
	},

	isContactForm7Connected({ profile }) {
		return Boolean(profile?.integrations?.contactForm7?.sites?.length);
	},

	isGravityConnected({ profile }) {
		return Boolean(profile?.integrations?.gravity?.sites?.length);
	},

	isFormConnected(state, getters) {
		return Boolean(
			getters.isWixConnected
			|| getters.isElementorConnected
			|| getters.isContactForm7Connected
			|| getters.isGravityConnected
		);
	},

	/**
	 * @param {Object} state
	 * @param {Object} state.profile
	 * @return {Boolean}
	 */
	isSalesforceConnected({ profile }) {
		return Boolean(profile?.integrations?.salesforce);
	},

	/**
	 * Only meant to be used in UI, not business logic.
	 * @param {Object} state
	 * @param {import('vuex').GetterTree} getters
	 * @return {String}
	 */
	activeContactSource(state, getters) {
		if (getters.isPipedriveConnected) {
			return ContactSourceNames.PIPEDRIVE;
		}

		if (getters.isCopperConnected) {
			return ContactSourceNames.COPPER;
		}

		if (getters.isHubspotCrmConnected) {
			return ContactSourceNames.HUBSPOT;
		}

		if (getters.isAirtableConnected) {
			return ContactSourceNames.AIRTABLE;
		}

		if (getters.isSalesforceConnected) {
			return ContactSourceNames.SALESFORCE;
		}

		if (!getters.isCrmConnected && getters.hasCopperIntent) {
			return ContactSourceNames.COPPER;
		}

		return ContactSourceNames.CRM;
	},

	/**
	 * @param {Object} state
	 * @param {import('vuex').GetterTree} getters
	 * @return {String}
	 */
	activeContactSourceId(state, getters) {
		if (getters.isPipedriveConnected) {
			return ContactSources.PIPEDRIVE;
		}

		if (getters.isCopperConnected) {
			return ContactSources.COPPER;
		}

		if (getters.isHubspotCrmConnected) {
			return ContactSources.HUBSPOT;
		}

		if (getters.isAirtableConnected) {
			return ContactSources.AIRTABLE;
		}

		if (getters.isSalesforceConnected) {
			return ContactSources.SALESFORCE;
		}

		return null;
	},

	profileIntegrations({ profile }) {
		return profile?.integrations;
	},

	pipedriveUserName({ profile }) {
		let name = '';

		if (profile?.integrations) {
			const { pipedrive } = profile.integrations;

			if (pipedrive) {
				({ name } = pipedrive);
			}
		}

		return name;
	},

	linkedInUserName({ profile }) {
		let name = '';

		if (profile?.integrations) {
			const { linkedin } = profile.integrations;

			if (linkedin) {
				({ name } = linkedin);
			}
		}

		return name;
	},

	hubspotCrmUserEmail({ profile }) {
		if (!profile?.integrations?.hubspotCrm) {
			return null;
		}

		return profile.integrations.hubspotCrm.email;
	},

	pipedriveCompanyName({ profile }) {
		let company = '';

		if (profile?.integrations) {
			const { pipedrive } = profile.integrations;

			if (pipedrive) {
				({ company } = pipedrive);
			}
		}

		return company;
	},

	copperAPIKey({ profile }) {
		let accesToken = '';

		if (profile?.integrations) {
			const { copper } = profile.integrations;

			if (copper) {
				({ accesToken } = copper);
			}
		}

		return accesToken;
	},

	copperEmail({ profile }) {
		let email = '';

		if (profile?.integrations) {
			const { copper } = profile.integrations;

			if (copper) {
				({ email } = copper);
			}
		}

		return email;
	},

	copperCompanyName({ profile }) {
		let companyName = '';

		if (profile?.integrations) {
			const { copper } = profile.integrations;

			if (copper) {
				({ companyName } = copper);
			}
		}

		return companyName;
	},

	activecampaignApiKey({ profile }) {
		return profile?.integrations?.activecampaign?.apiKey ?? '';
	},

	activecampaignAccountName({ profile }) {
		if (!profile?.integrations?.activecampaign) {
			return '';
		}

		const { accountName, apiUrl } = profile?.integrations?.activecampaign;

		return accountName || apiUrl;
	},

	copperAuthData({ profile }) {
		return {
			email: profile?.integrations?.copper?.email || '',
			apiKey: profile?.integrations?.copper?.accessToken || ''
		};
	},

	activecampaignAuthData({ profile }) {
		return profile?.integrations?.activecampaign ?? {};
	},

	airtableAuthData({ profile }) {
		return profile?.integrations?.airtable ?? {};
	},

	sendinblueAuthData({ profile }) {
		return { apiKey: profile?.integrations?.sendinblue?.apiKey ?? '' };
	},

	wixCompanyEmail({ profile }) {
		if (!profile?.integrations?.wix?.email) {
			return null;
		}

		return profile.integrations.wix.email;
	},

	wixSiteName({ profile }) {
		if (!profile?.integrations?.wix?.siteName) {
			return null;
		}

		return profile.integrations.wix.siteName;
	},

	isMailchimpConnected({ profile }) {
		return Boolean(profile?.integrations?.mailchimp);
	},

	isActiveCampaignConnected({ profile }) {
		return Boolean(profile?.integrations?.activecampaign);
	},

	hasSignedUpZendesk({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.ZENDESK));
	},

	hasSignedUpHubspot({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.HUBSPOT));
	},

	hasSignedUpHubspotToMailchimp({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.HUBSPOT_TO_MAILCHIMP));
	},

	hasSignedUpMailchimpToHubspot({ profile }) {
		return Boolean(profile?.flags?.includes(Flags.MAILCHIMP_TO_HUBSPOT));
	},

	mailchimpAccount({ profile }) {
		let account = '';

		if (profile?.integrations) {
			const { mailchimp } = profile.integrations;

			if (mailchimp) {
				({ account } = mailchimp);
			}
		}

		return account;
	},

	firsName({ profile }) {
		let firstName = '';

		if (profile?.name) {
			[firstName] = profile.name.split(' ');
		}

		return firstName;
	},

	lastName({ profile }) {
		let lastName = '';

		if (profile?.name) {
			[, lastName] = profile.name.split(' ');
		}

		return lastName;
	},

	company({ profile }) {
		return profile?.company ?? '';
	},

	userEmailAddress({ profile }) {
		return profile?.email ?? '';
	},

	userApiKey({ profile }) {
		const keys = profile?.apiKeys;

		return keys?.[keys?.length - 1]?.apiKey ?? '';
	},

	isInternalUser({ profile }) {
		if (!profile) {
			return false;
		}

		return profile.email?.endsWith('outfunnel.com');
	},

	/**
	 * @param {{profile: {featureFlags: {disableSingleEmailCampaigns: Boolean}}}} user
	 * @return {Boolean}
	 */
	areSingleEmailCampaignsEnabled({ profile }) {
		if (profile?.featureFlags?.disableSingleEmailCampaigns) {
			return false;
		}

		return true;
	},

	/**
	 * @param {{profile: {featureFlags: {isKlaviyoAuthEnabled: Boolean}}}} profile
	 * @return {Boolean}
	 */
	isKlaviyoOauthEnabled({ profile }) {
		if (!profile.featureFlags?.enableKlaviyoOauth) {
			return false;
		}

		return true;
	},

	/**
	 * @param {{profile: {featureFlags: {disableSequenceCampaigns: Boolean}}}} profile
	 * @return {Boolean}
	 */
	areSequenceCampaignsEnabled({ profile }) {
		if (profile?.featureFlags?.disableSequenceCampaigns) {
			return false;
		}

		return true;
	},

	/**
	 * @param {{profile: {featureFlags: {usageOnSeparatePage: Boolean}}}} user
	 * @return {Boolean}
	 */
	isUsageOnSeparatePage({ profile }) {
		return Boolean(profile?.featureFlags?.usageOnSeparatePage);
	},

	/**
	 * @param {{profile: {featureFlags: {disableAutomations: Boolean}}}} user
	 * @return {Boolean}
	 */
	areAutomationsDisabled({ profile }) {
		return Boolean(profile?.featureFlags?.disableAutomations);
	},

	/**
	 * @param {{profile: {featureFlags: {disableContactsPage: Boolean}}}} user
	 * @return {Boolean}
	 */
	isContactsPageDisabled({ profile }) {
		return Boolean(profile?.featureFlags?.disableContactsPage);
	},

	areAutomationsEnabled(state, getters) {
		return Boolean(getters.isPipedriveConnected && !getters.isConnectorEnabledForPipedrive);
	},

	/**
	 * @param {Object} params
	 * @param {{isInitialContactSyncDone?: Boolean}} params.profile
	 * @return {Boolean}
	 */
	isInitialContactSyncDone({ profile }) {
		return Boolean(profile?.isInitialContactSyncDone);
	},

	/**
	 * @param {{profile: {featureFlags: {disableEmailToolIntegration: Boolean}}}} user
	 * @return {Boolean}
	 */
	isEmailToolIntegrationEnabled({ profile }) {
		return !(profile?.featureFlags?.disableEmailToolIntegration);
	},

	/**
	 * @param {{profile: {featureFlags: {disableEmailToolIntegration: Boolean}}}} user
	 * @return {Boolean}
	 */
	isLeadSourceTrackingEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableLeadSourceTracking);
	},

	/**
	 * @param {Object} state
	 * @param {{
	 * isPipedriveConnected: Boolean,
	 * isConnectorEnabledForPipedrive: Boolean,
	 * isOutfunnelDatabaseConnected: Boolean
	 * }} getters
	 * @return {Boolean}
	 */
	isAccountFieldsVisible(state, getters) {
		return [
			[
				getters.isPipedriveConnected,
				!getters.isConnectorEnabledForPipedrive
			].every(Boolean),
			!getters.isOutfunnelDatabaseConnected
		].every(Boolean);
	},

	/**
	 * @param {{profile: {featureFlags: {showPartialProfilePage: Boolean}}}} user
	 * @return {Boolean}
	 */
	isPartialProfileVisible({ profile }) {
		return (profile?.featureFlags?.showPartialProfilePage);
	},

	/**
	 * @param {{profile: {featureFlags: {hideIntegrationsPage: Boolean}}}} user
	 * @return {Boolean}
	 */
	isIntegrationsVisible({ profile }) {
		return !(profile?.featureFlags?.hideIntegrationsPage);
	},

	/**
	 * @param {Object} state
	 * @param {{
	 * isPipedriveConnected: Boolean
	 * isCopperConnected: Boolean
	 * areSequenceCampaignsEnabled: Boolean
	 * }} getters
	 * @return {Boolean}
	 */
	isEmailEngagementSettingsSupported(state, getters) {
		if (!getters.areSequenceCampaignsEnabled && !getters.areSingleEmailCampaignsEnabled) {
			return false;
		}

		return Boolean(getters.isPipedriveConnected || getters.isCopperConnected);
	},

	/**
	 * @param {{profile: {featureFlags: {hideBillingPage: Boolean}}}} user
	 * @return {Boolean}
	 */
	isBillingVisible({ profile }) {
		return !(profile?.featureFlags?.hideBillingPage);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { disableBillingNotifications?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	areBillingNotificationsEnabled({ profile }) {
		return !(profile?.featureFlags?.disableBillingNotifications);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { disableLiveChat?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isLiveChatEnabled({ profile }) {
		return !(profile?.featureFlags?.disableLiveChat);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableCopperOEMWelcomeInfobox?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isCopperOEMWelcomeInfoboxEnabled({ profile }) {
		return profile?.featureFlags?.enableCopperOEMWelcomeInfobox;
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { disableTestEmailRecipientAutofill?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isTestEmailRecipientAutofillEnabled({ profile }) {
		return !(profile?.featureFlags?.disableTestEmailRecipientAutofill);
	},

	/**
	 * @param {Object} state
	 * @param {{
	 * featureFlags: { disableConnector?: Boolean, enableConnectorForCopper?: Boolean, enableConnectorForPipedrive?: Boolean }, hubspot?: Object, copper?: Object, pipedrive?: Object
	 * }} state.profile
	 * @param {Object} getters
	 * @return {Boolean}
	 */
	isConnectorEnabled({ profile }, getters) {
		// TODO remove when disableConnector FF is not in use anymore
		const isConnectorDisabledViaFeatureFlag = profile?.featureFlags?.disableConnector;

		if (isConnectorDisabledViaFeatureFlag) {
			return false;
		}

		const isConnectorLiteEnabled = profile?.featureFlags?.enableConnectorLite;

		if (isConnectorLiteEnabled) {
			return true;
		}

		const isConnectorDisabledForPipedrive = getters.isPipedriveConnected
			&& !getters.isConnectorEnabledForPipedrive;

		return !isConnectorDisabledForPipedrive;
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableConnectorForPipedrive?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isConnectorEnabledForPipedrive({ profile }) {
		return Boolean(profile?.featureFlags?.enableConnectorForPipedrive);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableConnectorForPipedrive?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isConnectorEnabledForAirtable({ profile }) {
		if (profile?.featureFlags?.disableAirtableForConnector) {
			return false;
		}

		return true;
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableConnectorForLinkedin?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isConnectorEnabledForLinkedin({ profile }) {
		return Boolean(profile?.featureFlags?.enableConnectorForLinkedin);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableConnectorLite?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isConnectorLiteEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableConnectorLite);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableConnectorForKlaviyo?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isConnectorEnabledForKlaviyo({ profile }) {
		return Boolean(profile?.featureFlags?.enableConnectorForKlaviyo);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableConnectorForWix?: Boolean }}} state.profile
	 * @return {Boolean}
	 */
	isConnectorEnabledForWix({ profile }) {
		return Boolean(profile?.featureFlags?.enableConnectorForWix);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { finishedConnectorMigration?: Boolean}}} state.profile
	 * @return {Boolean}
	 */
	hasFinishedConnectorMigration({ profile }) {
		return Boolean(profile?.featureFlags?.finishedConnectorMigration);
	},

	airtableBaseId({ profile }) {
		return profile?.integrations?.airtable?.baseId;
	},

	airtableTableName({ profile }) {
		return profile?.integrations?.airtable?.tableName;
	},

	/**
	 * @param {Object} _state
	 * @return {Boolean}
	 */
	isDealCreationEnabled(_state, getters) {
		return Boolean(getters.isPipedriveConnected);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { isLeadCreationEnabled?: Boolean}}} state.profile
	 * @return {Boolean}
	 */
	isPipedriveLeadCreationEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enablePipedriveLeadCreation);
	},

	/**
	 * @param {Object} state
	 * @param {{featureFlags: { enableContactUpload?: Boolean}}} state.profile
	 * @return {Boolean}
	 */
	isContactUploadEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableContactUpload);
	},

	/**
	 * @param {{profile: {featureFlags: {enableContactCreationUi: Boolean}}}} user
	 * @return {Boolean}
	 */
	isContactCreationVisible({ profile }) {
		return Boolean(profile?.featureFlags?.enableContactCreationUi);
	},

	/**
	 * @param {Object} state
	 * @param {Object} state.profile
	 * @return {Object}
	 */
	locales({ profile }) {
		return profile.locales;
	},

	/**
	 * @param {Object} state
	 * @param {Object} state.profile
	 * @return {String}
	 */
	calendlyUserName({ profile }) {
		return profile.integrations?.calendly?.name;
	},

	/**
	 * @param {Object} state
	 * @param {Object} state.profile
	 * @return {String}
	 */
	calendlyUserEmail({ profile }) {
		return profile.integrations?.calendly?.email;
	},

	/**
	 * @param {Object} state
	 * @param {Object} state.profile
	 * @return {String}
	 */
	salesforceUserName({ profile }) {
		return profile.integrations?.salesforce?.name;
	},

	/**
	 * @param {Object} state
	 * @param {Object} state.profile
	 * @return {String}
	 */
	salesforceUserEmail({ profile }) {
		return profile.integrations?.salesforce?.email;
	},

	areFeaturesLoaded(state) {
		return Boolean(state.features);
	},

	isFeatureEnabled(state) {
		return featureId => {
			if (!state.features) {
				return false;
			}

			return state.features[featureId]?.enabled ?? false;
		};
	},

	requiredPlanForFeature(state) {
		return featureId => state.features[featureId]?.requiredPlan;
	},

	isSendinblueConnected({ profile }) {
		return Boolean(profile?.integrations?.sendinblue);
	},

	sendinblueUserEmail({ profile }) {
		return profile?.integrations?.sendinblue?.email;
	},

	sendinblueUserName({ profile }) {
		return profile?.integrations?.sendinblue?.name;
	},

	/**
	 * @param {Object} state
	 * @param {{integrations: { hubspotMkt?: Object}}} state.profile
	 * @return {Boolean}
	 */
	isHubspotMktConnected({ profile }) {
		return Boolean(profile?.integrations?.hubspotMkt);
	},

	/**
	 * @param {Object} state
	 * @param {{integrations: { hubspotMkt?: Object}}} state.profile
	 * @return {String}
	 */
	hubspotMktUserEmail({ profile }) {
		if (!profile?.integrations?.hubspotMkt) {
			return null;
		}

		return profile.integrations.hubspotMkt.email;
	},

	elementorSiteUrl({ profile }) {
		if (!profile?.integrations?.elementor?.sites?.length) {
			return null;
		}

		return profile?.integrations?.elementor?.sites[0].url;
	},

	contactForm7SiteUrl({ profile }) {
		if (!profile?.integrations?.contactForm7?.sites?.length) {
			return null;
		}

		return profile?.integrations?.contactForm7?.sites[0].url;
	},

	gravitySiteUrl({ profile }) {
		if (!profile?.integrations?.gravity?.sites?.length) {
			return null;
		}

		return profile?.integrations?.gravity?.sites[0].url;
	},

	facebookAccountName({ profile }) {
		if (!profile?.integrations?.facebook) {
			return null;
		}

		return profile?.integrations?.facebook?.name ?? '';
	},

	getLeadScoreRulesLimit(state) {
		return featureId => state.features[featureId]?.rulesLimit ?? null;
	},

	/**
	 * @param {{profile: {featureFlags: {enableCrmActivitiesLeadScoring: Boolean}}}} user
	 * @return {Boolean}
	 */
	isCrmActivitiesLeadScoringEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableCrmActivitiesLeadScoring);
	},

	/**
	 * @return {Boolean}
	 */
	isValidActivitySourceConnected(state, getters) {
		return Boolean(getters.isPipedriveConnected || getters.isCopperConnected);
	},

	/**
	 * @param {{profile: {featureFlags: {enableActiveContactPricingPlans: Boolean}}}} user
	 * @return {Boolean}
	 */
	areActiveContactPricingPlansEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableActiveContactPricingPlans);
	},

	/**
	 * @param {{profile: {featureFlags: {enableCrmActivitiesRulesForOldPlans: Boolean}}}} user
	 * @return {Boolean}
	 */
	isCrmActivitiesLeadScoringRulesForOldPlansEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableCrmActivitiesRulesForOldPlans);
	},

	isConnectorEngagementSyncEnabled(state) {
		return state.features?.connector?.engagementSync?.enabled ?? false;
	},

	/**
	 * @param {{profile: {featureFlags: {enableDashboard: Boolean}}}} user
	 * @return {Boolean}
	 */
	isDashboardEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableDashboard);
	},

	isEmailEditEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableEmailEdit);
	},

	isGlobalFieldMappingEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableGlobalFieldMapping);
	},

	isConnectorV3Enabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableConnectorExperienceV3);
	},

	areMultipleContactSyncRulesEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableMultipleContactSyncRules);
	},

	isTwoWaySyncEnabledForHubspot({ profile }) {
		return Boolean(profile?.featureFlags?.enableTwoWaySyncForHubspot);
	},

	isGoogleSheetsContactSyncEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableGoogleSheetsContactSync);
	},

	/**
	 * @param {{profile: {featureFlags: {enableAccurateSyncTimeCounter: Boolean}}}} user
	 * @return {Boolean}
	 */
	isAccurateSyncCounterEnabled({ profile }) {
		return Boolean(profile?.featureFlags?.enableAccurateSyncTimeCounter);
	},

	isCalendlyPlanUpgradeRequired({ profile }) {
		return Boolean(profile?.integrations?.calendly?.isPlanUpgradeRequired);
	},

	isKlaviyoConnected({ profile }) {
		return Boolean(profile?.integrations?.klaviyo);
	},

	klaviyoOrganization({ profile }) {
		return profile?.integrations?.klaviyo?.name;
	},

	klaviyoSiteId({ profile }) {
		return profile?.integrations?.klaviyo?.id;
	},

	klaviyoAuthData({ profile }) {
		return { apiKey: profile?.integrations?.klaviyo?.apiKey ?? '' };
	},

	isMailerliteConnected({ profile }) {
		return Boolean(profile?.integrations?.mailerlite);
	},

	mailerliteOrganization({ profile }) {
		return profile?.integrations?.mailerlite?.name;
	},

	mailerliteEmail({ profile }) {
		return profile?.integrations?.mailerlite?.senderEmail;
	},

	mailerliteAuthData({ profile }) {
		return { apiKey: profile?.integrations?.mailerlite?.apiKey ?? '' };
	},

	isMondayConnected({ profile }) {
		return Boolean(profile?.integrations?.monday);
	},

	mondayUserEmail({ profile }) {
		return profile?.integrations?.monday?.email;
	},

	mondayAccountName({ profile }) {
		return profile?.integrations?.monday?.accountName;
	},

	/**
	 * @param {{profile: {integrations: {klaviyo: {webhookSubscribed: Boolean}}}}} user
	 * @return {Boolean}
	 */
	isKlaviyoWebhookSubscribed({ profile }) {
		return Boolean(profile?.integrations?.klaviyo?.webhookSubscribed);
	},

	isIntegrationConnected({ profile }) {
		return integration => Boolean(profile?.integrations?.[integration]);
	},

	integrationField({ profile }) {
		return (integration, field) => profile?.integrations?.[integration]?.[field];
	},

	isGetresponseConnected({ profile }) {
		return Boolean(profile?.integrations?.getresponse);
	},

	isConstantcontactConnected({ profile }) {
		return Boolean(profile?.integrations?.constantcontact);
	},

	isSendgridConnected({ profile }) {
		return Boolean(profile?.integrations?.sendgrid);
	},

	isGooglesheetsConnected({ profile }) {
		return Boolean(profile?.integrations?.googlesheets);
	}
};
